<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Consider the theoretical reaction shown below:</p>

      <p class="mb-5 pl-14">
        <chemical-latex content="A(aq) + 3 B(aq) -> 2 C(aq)" />
      </p>

      <p class="mb-2">
        a) If the equilibrium constant,
        <stemble-latex content="$\text{K,}$" />
        is
        <number-value :value="Kc" />
        at
        <stemble-latex content="$25^\circ\text{C,}$" />
        determine
        <stemble-latex content="$\Delta\text{G}^\circ$" />
        for the reaction in kJ/mol.
      </p>

      <calculation-input
        v-model="inputs.deltaGrxn"
        class="mb-5"
        prepend-text="$\Delta\text{G}^\circ:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) If the free energy of formation,
        <stemble-latex content="$\Delta\text{G}^\circ_\text{f},$" />
        for
        <chemical-latex content="B(aq)" /> and <chemical-latex content="C(aq)" /> are
        <number-value :value="deltaGfB" unit="\text{kJ/mol}" /> and
        <number-value :value="deltaGfC" unit="\text{kJ/mol,}" />
        respectively, determine
        <stemble-latex content="$\Delta\text{G}^\circ_\text{f}$" />
        for
        <chemical-latex content="A(aq)." />
      </p>

      <calculation-input
        v-model="inputs.deltaGf"
        prepend-text="$\Delta\text{G}^\circ_\text{f}\text{[A(aq)]:}$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';

export default {
  name: 'Question423',
  components: {
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        deltaGrxn: null,
        deltaGf: null,
      },
    };
  },
  computed: {
    Kc() {
      return this.taskState.getValueBySymbol('Kc').content;
    },
    deltaGfB() {
      return this.taskState.getValueBySymbol('deltaGfB').content;
    },
    deltaGfC() {
      return this.taskState.getValueBySymbol('deltaGfC').content;
    },
  },
};
</script>
